import { ValuePair } from 'gigabyte-shared';
import defaultUserIcon from './../assets/images/avatars/default-user.jpg';
import bear from './../assets/images/avatars/bear.png';
import boy from './../assets/images/avatars/boy.png';
import chicken from './../assets/images/avatars/chicken.png';
import gamer from './../assets/images/avatars/gamer.png';
import man1 from './../assets/images/avatars/man1.png';
import man2 from './../assets/images/avatars/man2.png';
import woman1 from './../assets/images/avatars/woman1.png';
import woman2 from './../assets/images/avatars/woman2.png';
import meerkat from './../assets/images/avatars/meerkat.png';

export const defaultAvatarIcon = { key: 'defaultUserIcon', value: defaultUserIcon };

export const allAvatarIcons: ValuePair[] = [
  defaultAvatarIcon,
  { key: 'bear', value: bear },
  { key: 'boy', value: boy },
  { key: 'chicken', value: chicken },
  { key: 'gamer', value: gamer },
  { key: 'man1', value: man1 },
  { key: 'man2', value: man2 },
  { key: 'woman1', value: woman1 },
  { key: 'woman2', value: woman2 },
  { key: 'meerkat', value: meerkat },
];

export const getAvatarIcon = (avatarName?: string): ValuePair => {
  if (!avatarName?.length) return defaultAvatarIcon;

  const avatarIcon = allAvatarIcons.find((avatar) => avatar!.key === avatarName);

  return avatarIcon ?? defaultAvatarIcon;
};
