import { ReportFormatEnum, ReportParamsInterface, ReportTypeEnum } from 'gigabyte-shared';
import { DateRange } from '../types/format';
import moment from 'moment';

export const getReportBodyParams = (dateRange: DateRange, type: ReportTypeEnum, format: ReportFormatEnum): ReportParamsInterface => {
  const body: ReportParamsInterface = { type, format: format };

  // TODO: We are passing local dates, didn't we agree to always pass UTC?
  if (dateRange.startDate && dateRange.endDate) {
    const startDate = moment(dateRange.startDate).toDate();
    body.startDate = new Date(startDate.getFullYear(), startDate.getMonth(), startDate.getDate(), 0, 0, 0);
    const endDate = moment(dateRange.endDate).toDate();
    body.endDate = new Date(endDate.getFullYear(), endDate.getMonth(), endDate.getDate(), 23, 59, 59);
  }

  return body;
};
