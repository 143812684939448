export function log(log: any, ...optionalParams: any[]) {
  if (isNonProd()) {
    console.log(log, optionalParams);
  }
}

export function isNonProd() {
  const env = (process.env.NODE_ENV || 'test').toUpperCase();
  return !['PRODUCTION', 'PRD', 'PROD'].includes(env);
}
