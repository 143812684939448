import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ServiceAlertInterface } from 'gigabyte-shared';

const initialState: ServiceAlertInterface[] = [];

const loadAlerts = (_state: ServiceAlertInterface[], action: PayloadAction<ServiceAlertInterface[]>) => {
  return action.payload;
};

const AlertSlice = createSlice({
  name: 'alert',
  initialState: initialState,
  reducers: {
    loadAlerts: loadAlerts,
  },
});

export default AlertSlice;
