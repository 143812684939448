import { configureStore } from '@reduxjs/toolkit';
import { useDispatch } from 'react-redux';
import AlertSlice from './slices/AlertSlice';
import ErrorSlice from './slices/ErrorsSlice';
import ThemeSlice from './slices/ThemeSlice';
import ModalSlice from './slices/ModalSlice';

const store = configureStore({
  reducer: {
    theme: ThemeSlice.reducer,
    error: ErrorSlice.reducer,
    alert: AlertSlice.reducer,
    modal: ModalSlice.reducer
  },
});

export const themeAction = ThemeSlice.actions;
export const errorAction = ErrorSlice.actions;
export const alertAction = AlertSlice.actions;
export const modalAction = ModalSlice.actions;

export default store;

export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;
export const useAppDispatch: () => AppDispatch = useDispatch;
