import { UserInterface, JwtTokenPayload } from 'gigabyte-shared';
import jwt_decode from 'jwt-decode';

const setSession = (accessToken: string | null | undefined, id: string | null, userProfile: UserInterface | null) => {
  if (accessToken !== null && id !== null) {
    window.localStorage.setItem('x-user-id', id);
    if (accessToken !== undefined) {
      window.localStorage.setItem('accessToken', accessToken);
    }
    if (userProfile !== null && userProfile !== undefined) {
      window.localStorage.setItem('userProfile', JSON.stringify(userProfile));
    }
  } else {
    window.localStorage.removeItem('accessToken');
    window.localStorage.removeItem('x-user-id');
    window.localStorage.removeItem('userProfile');
  }
};

const isSessionActive = (): boolean => {
  const accessToken = window.localStorage.getItem('accessToken');
  const id = window.localStorage.getItem('x-user-id');
  const userProfSession = window.localStorage.getItem('userProfile');
  if (accessToken !== null && isTokenExpired()) {
    setSession(null, null, null);
    return false;
  }

  return accessToken !== null && id !== null && userProfSession !== null;
};

const isTokenExpired = (): boolean => {
  const accessToken = window.localStorage.getItem('accessToken');

  if (accessToken != null) {
    const decodedToken: JwtTokenPayload = jwt_decode(accessToken);
    if (decodedToken.exp * 1000 < new Date().getTime()) {
      return true;
    } else {
      return false;
    }
  } else {
    return true;
  }
};

export { setSession, isSessionActive, isTokenExpired };
