import { createSlice } from '@reduxjs/toolkit';


export type ModalState = {
    queueZIndex: number
};

const initialState: ModalState = {
   queueZIndex: 0
};

const increaseModalOverlap = (state: ModalState) => {
    state.queueZIndex = state.queueZIndex + 1;
}

const decreaseModalOverlap = (state: ModalState) => {
    if(state.queueZIndex > 0){
        state.queueZIndex = state.queueZIndex - 1;
    }
}

const ThemeSlice = createSlice({
    name: 'zModal',
    initialState: initialState,
    reducers: {
        increaseModalOverlap,
        decreaseModalOverlap
    },
  });
  
  export default ThemeSlice;