import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type ThemeState = {
  sidebarShow: boolean;
  sidebarUnfoldable: boolean;
  asideShow: boolean;
  theme: string;
};

const initialState: ThemeState = {
  sidebarShow: true,
  sidebarUnfoldable: true,
  asideShow: false,
  theme: 'default',
};

const changeThemeColor = (state: ThemeState, action: PayloadAction<string>) => {
  state.theme = action.payload;
};

const changeThemeSideBarShow = (state: ThemeState, action: PayloadAction<boolean>) => {
  state.sidebarShow = action.payload;
};

const changeThemeAsideBarShow = (state: ThemeState, action: PayloadAction<boolean>) => {
  state.asideShow = action.payload;
};

const changeThemeSidebarUnfoldable = (state: ThemeState, action: PayloadAction<boolean>) => {
  state.sidebarUnfoldable = action.payload;
};

const ThemeSlice = createSlice({
  name: 'theme',
  initialState: initialState,
  reducers: {
    changeThemeColor: changeThemeColor,
    changeThemeSideBarShow: changeThemeSideBarShow,
    changeThemeAsideBarShow: changeThemeAsideBarShow,
    changeThemeSidebarUnfoldable: changeThemeSidebarUnfoldable,
  },
});

export default ThemeSlice;
