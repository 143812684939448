import { UserInterface, UserRolesEnum } from 'gigabyte-shared';

export const getPermission = (user: UserInterface | null | undefined, roles: UserRolesEnum[]) => {
  let alertPermission = false;
  if (user) {
    for (const role of roles) {
      alertPermission = alertPermission || user.roles.find((rol) => rol === role) !== undefined;
    }
  }
  return alertPermission;
};

export const getWritePermission = (currentUserRoles: UserRolesEnum[], targetUserRoles: UserRolesEnum[]) => {
  let result = false;

  for (const role of currentUserRoles) {
    switch (role) {
      case UserRolesEnum.ROOT:
        result = result || true;
        break;
      case UserRolesEnum.ADMIN:
        const highAdminRole = targetUserRoles.find((roleItem) => roleItem === UserRolesEnum.ROOT);
        if (!highAdminRole) {
          result = result || true;
        }
        break;
      case UserRolesEnum.COORDINATOR:
        const highCoordRole = targetUserRoles.find((roleItem) => roleItem === UserRolesEnum.ROOT || roleItem === UserRolesEnum.ADMIN);
        if (!highCoordRole) {
          result = result || true;
        }
        break;
      default:
        result = result || false;
        break;
    }
  }

  return result;
};
