import React, { Component, Suspense } from 'react';
import { HashRouter, Navigate, Route, Routes } from 'react-router-dom';
import { CSpinner } from '@coreui/react-pro';
import './scss/style.scss';
import TechServiceSingleReport from './views/tech-service/TechServiceSingleReport';

// Containers
const DefaultLayout = React.lazy(() => import('./layout/DefaultLayout'));
const BlankLayout = React.lazy(() => import('./layout/BlankLayout'));

class App extends Component {
  render(): JSX.Element {
    return (
      <HashRouter>
        <Suspense fallback={<CSpinner color="primary" />}>
          <Routes>
            <Route path="/" element={<Navigate to="/dashboard" />} />
            <Route path="/dashboard/*" element={<DefaultLayout />} />
            <Route path="/auth/*" element={<BlankLayout />} />
            <Route path="/report/tech-service/:id" element={<TechServiceSingleReport />} />
          </Routes>
        </Suspense>
      </HashRouter>
    );
  }
}

export default App;
