import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type ErrorState = {
  showError: boolean;
  message: string;
  errorCode: number;
};

const initialState: ErrorState[] = [];

const activeError = (state: ErrorState[], action: PayloadAction<ErrorState>) => {
  const index = state.findIndex((err) => err.message === action.payload.message);
  if (index > -1) {
    state.splice(index, 1);
  }
  state.push(action.payload);
};

const resetError = (state: ErrorState[]) => {
  state.splice(0, state.length);
};

const ErrorSlice = createSlice({
  name: 'error',
  initialState: initialState,
  reducers: {
    activeError: activeError,
    resetError: resetError,
  },
});

export default ErrorSlice;
