import { AxiosError, AxiosResponse } from 'axios';
import { useState, useContext } from 'react';
import axios, { getHttpErrorResponse, sendError } from '../store/jwt/axios';
import { errorAction, useAppDispatch } from '../store/store';
import { log } from '../utils';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../store/jwt/JwtContext';
import { isNil, isString } from 'lodash';
import { GenericValuePair, SystemLogLevelEnum, ValuePair } from 'gigabyte-shared';

export type HTTPCoreErrorResponse = {
  statusCode: number;
  timestamp: Date;
  message: string;
  detail: {
    statusCode: number;
    message: string[];
    error: string;
  };
  path: string;
};

interface requestOptionsInterface {
  callback?: (data: any) => void;
  body?: any;
  headers?: ValuePair;
  queryParameters?: GenericValuePair | null;
}

export enum HttpMethodEnum {
  GET = 'GET',
  POST = 'POST',
  PUT = 'PUT',
  DELETE = 'DELETE',
}

const getBodyStr = (body: any): any => {
  if (!body) return '';
  return !isString(body) ? JSON.stringify(body) : body;
};

const buildQueryParametersStr = (queryParameters?: ValuePair | null): string => {
  if (isNil(queryParameters)) return '';
  let result = '?';
  Object.keys(queryParameters).forEach((key) => {
    if (result.length > 1) result += '&';
    result += `${key}=${queryParameters[key]}`;
  });
  return result;
};

const useHttp = () => {
  const dispatch = useAppDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const navigate = useNavigate();
  const { logout } = useContext(AuthContext);

  const sendPostRequest = async (path: string, opt?: requestOptionsInterface) => {
    return sendRequest(path, HttpMethodEnum.POST, opt);
  };

  const sendDeleteRequest = async (path: string, opt?: requestOptionsInterface) => {
    return sendRequest(path, HttpMethodEnum.DELETE, opt);
  };

  const sendPutRequest = async (path: string, opt?: requestOptionsInterface) => {
    return sendRequest(path, HttpMethodEnum.PUT, opt);
  };

  const sendGetRequest = async (path: string, opt?: requestOptionsInterface) => {
    return sendRequest(path, HttpMethodEnum.GET, opt);
  };

  const sendRequest = async (path: string, method: HttpMethodEnum, opt?: requestOptionsInterface) => {
    setIsLoading(true);
    setError(null);
    let response: AxiosResponse<any, any> | null = null;
    const finalUrl = `${path}${buildQueryParametersStr(opt?.queryParameters)}`;
    const headers = opt?.headers;
    try {
      switch (method) {
        case HttpMethodEnum.GET:
          response = await axios.get(finalUrl, { headers });
          break;
        case HttpMethodEnum.POST: {
          response = await axios.post(finalUrl, getBodyStr(opt?.body), { headers });
          break;
        }
        case HttpMethodEnum.PUT: {
          response = await axios.put(finalUrl, getBodyStr(opt?.body), { headers });
          break;
        }
        case HttpMethodEnum.DELETE:
          response = await axios.delete(finalUrl, { headers });
          break;
        default:
          throw Error('Not implemented http Request type');
      }
      log('Response: ' + JSON.stringify(response));
      if (response !== null) {
        if (response.status >= 200 || response.status < 300) {
          opt?.callback && opt.callback(response.data);
        } else {
          setError(response.statusText);
          dispatch(errorAction.activeError({ showError: true, message: response.statusText, errorCode: response.status }));
        }
      }
    } catch (error: any) {
      const httpCoreError = error as HTTPCoreErrorResponse;
      const axiosError = error as AxiosError;
      log(JSON.stringify(axiosError));
      let code = 500;
      let message = 'Unknown error';

      if (httpCoreError.statusCode === 401) {
        logout().then((result) => {
          navigate('/auth/login');
        });
      }

      message = getHttpErrorResponse(error);

      if (httpCoreError && httpCoreError.detail) {
        code = httpCoreError.statusCode;
      } else if (axiosError && axiosError.isAxiosError) {
        code = axiosError.status!;
      }
      
      setError(message);
      dispatch(errorAction.activeError({ showError: true, message: message, errorCode: code }));
      sendError(SystemLogLevelEnum.ERROR, message, JSON.stringify(error));
    }
    setIsLoading(false);

    return response;
  };

  return { isLoading, error, sendRequest, sendGetRequest, sendPostRequest, sendPutRequest, sendDeleteRequest } as const;
};

export default useHttp;
