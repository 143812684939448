export enum CoreAPI {
  REACT_APP_API_ADMIN_ALERT_URL = '/service/alert/admin',
  REACT_APP_API_ADMIN_DISMISS_ALERT_URL = '/service/alert/admin/dismiss',
  REACT_APP_API_ALARM_EVENT_HEALTHCHECK_URL = '/event/healthcheck',
  REACT_APP_API_ALARM_EVENT_URL = '/event',
  REACT_APP_API_ALERT_PROCESS_URL = '/service/alert/admin/trigger/processing',
  REACT_APP_API_ASSIGNMENT_BY_UNIT_URL = '/admin/unit/assignation/by-unit',
  REACT_APP_API_ASSIGNMENT_BY_USER_URL = '/admin/unit/assignation/by-user',
  REACT_APP_API_ASSIGNMENT_URL = '/admin/unit/assignation',
  REACT_APP_API_CLIENT_ID_URL = '/client/id',
  REACT_APP_API_CLIENT_URL = '/client',
  REACT_APP_API_EVENT_CLIENT_DEFINITION_BY_CLIENT_URL = '/event/client-definition/custom',
  REACT_APP_API_EVENT_CLIENT_DEFINITION_URL = '/event/client-definition',
  REACT_APP_API_EVENT_DEFINITION_ID_URL = '/event/definition/id',
  REACT_APP_API_EVENT_DEFINITION_URL = '/event/definition',
  REACT_APP_API_EVENT_HEALTHCHECK_URL = '/event/healthcheck',
  REACT_APP_API_EVENT_HISTORY_URL = '/event/history',
  REACT_APP_API_EVENT_ID_URL = '/event/id',
  REACT_APP_API_EVENT_URL = '/event',
  REACT_APP_API_LOGIN_URL = '/user/auth/login',
  REACT_APP_API_PRICELIST_ID_URL = '/tech-srv/price-list/id',
  REACT_APP_API_PRICELIST_URL = '/tech-srv/price-list',
  REACT_APP_API_PRODUCT_SINGLE_URL = '/tech-srv/product/id',
  REACT_APP_API_PRODUCT_URL = '/tech-srv/product',
  REACT_APP_API_REPORT_URL = '/report/generate',
  REACT_APP_API_REPORT_MONITORING_URL = '/report/monitoring',
  REACT_APP_API_SERVICE_FUELING_ID_URL = '/service/fueling/id',
  REACT_APP_API_SERVICE_FUELING_URL = '/service/fueling',
  REACT_APP_API_SERVICE_ID_URL = '/service/id',
  REACT_APP_API_SERVICE_UNIT_ID_URL = '/service/unit/id',
  REACT_APP_API_SERVICE_UNIT_URL = '/service/unit',
  REACT_APP_API_SERVICE_URL = '/service',
  REACT_APP_API_SYSTEM_LOG_ID_URL = '/system-log/id',
  REACT_APP_API_SYSTEM_LOG_URL = '/system-log',
  REACT_APP_API_TECH_SERVICE_ID_REPORT_URL = '/report/tech-service',
  REACT_APP_API_TECH_SERVICE_ID_URL = '/tech-srv/id',
  REACT_APP_API_TECH_SERVICE_URL = '/tech-srv',
  REACT_APP_API_UNIT_DOCS_BY_ID_URL = '/unit/docs/id',
  REACT_APP_API_UNIT_DOCS_URL = '/unit/docs',
  REACT_APP_API_UNIT_SERVICE_INIT = '/service/unit-init',
  REACT_APP_API_UNIT_SINGLE_URL = '/unit/id',
  REACT_APP_API_UNIT_URL = '/unit',
  REACT_APP_API_USER_PASSWORD_URL = '/user/password',
  REACT_APP_API_USER_PROFILE_URL = '/user/profile?source=WEB',
  REACT_APP_API_USER_SINGLE_URL = '/user/id',
  REACT_APP_API_USER_URL = '/user',
}
