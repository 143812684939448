import React, { createContext, useEffect, useReducer } from 'react';
import axios from './axios';
import { setSession, isTokenExpired } from './Jwt';
import { HeaderEnum, UserAuthInterface, UserInterface } from 'gigabyte-shared';
import { CoreAPI } from '../../constants/core-api';
import { log } from '../../utils';

type AuthState = {
  isAuthenticated?: boolean;
  isInitialized?: boolean;
  userAuth: UserAuthInterface | null | undefined;
  user: UserInterface | null | undefined;
  message?: string | null | undefined;
};

type Action = {
  payload?: AuthState;
  type: string;
};

type LoginResponse = {
  user: UserInterface | null;
  code: number;
  message: string | undefined;
};

const initialState: AuthState = {
  isAuthenticated: false,
  isInitialized: false,
  userAuth: null,
  user: null,
  message: null,
};

const handlers = {
  INITIALIZE: (state: AuthState, action: Action) => {
    const { isAuthenticated, isInitialized, user, userAuth, message } = action.payload!;
    return {
      ...state,
      isAuthenticated,
      isInitialized,
      user,
      userAuth,
      message,
    };
  },
  UPDATE_PROFILE: (state: AuthState, action: Action) => {
    const { user } = action.payload!;
    return {
      ...state,
      user,
    };
  },
  LOGIN: (state: AuthState, action: Action) => {
    const { user, userAuth } = action.payload!;

    return {
      ...state,
      isAuthenticated: true,
      user,
      userAuth,
    };
  },
  LOGOUT: (state: AuthState) => ({
    ...state,
    isAuthenticated: false,
    isInitialized: false,
    user: null,
    userAuth: null,
  }),
};

type ObjectKey = keyof typeof handlers;

const reducer = (state: AuthState, action: Action) => {
  const attribute = action.type as ObjectKey;
  return handlers[attribute] ? handlers[attribute](state, action) : state;
};

const AuthContext = createContext({
  ...initialState,
  method: 'jwt',
  login: (email: string, password: string) => Promise.resolve<LoginResponse | null>(null),
  logout: () => Promise.resolve<boolean>(false),
  updateProfile: () => Promise.resolve<void>(undefined),
});

interface AuthProviderProps {
  children: React.ReactNode;
}

const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const accessTokenLStr = window.localStorage.getItem('accessToken');
  const accessTokenLS = accessTokenLStr !== null ? accessTokenLStr : '';
  log('initToken:' + accessTokenLS);

  const id = window.localStorage.getItem('x-user-id');
  const userProfSession = window.localStorage.getItem('userProfile');
  const userProfile: UserInterface = userProfSession !== null ? JSON.parse(userProfSession) : null;

  useEffect(() => {
    const initialize = async () => {
      log('TokenExpired:' + isTokenExpired());
      if (accessTokenLS !== null && accessTokenLS !== '' && !isTokenExpired()) {
        log('User authenticated!');
        setSession(accessTokenLS, id, userProfile);
        dispatch({
          type: 'INITIALIZE',
          payload: {
            isAuthenticated: true,
            isInitialized: true,
            userAuth: { accessToken: accessTokenLS, id: id! },
            user: userProfile,
          },
        });
      } else {
        setSession(null, null, null);
        dispatch({
          type: 'INITIALIZE',
          payload: {
            isAuthenticated: false,
            isInitialized: false,
            userAuth: null,
            user: null,
          },
        });
      }
    };

    initialize();
  }, []);

  const login = async (username: string, password: string) => {
    let userAuth: UserAuthInterface | null = null;
    let user: UserInterface | null = null;
    let message = undefined;
    let code = 500;

    try {
      const response = await axios.get(`${CoreAPI.REACT_APP_API_LOGIN_URL}`, {
        headers: {
          username: username,
          password: password,
        },
      });

      userAuth = response.data;
      code = response.status;

      if (response.status === 200 && userAuth) {
        window.localStorage.setItem('accessToken', userAuth.accessToken!);
        const responseProf = await axios.get(CoreAPI.REACT_APP_API_USER_PROFILE_URL, {
          headers: {
            'x-user-id': userAuth.id,
            Authorization: `Bearer ${userAuth.accessToken}`,
          },
        });

        code = response.status;
        if (responseProf.status >= 200 && responseProf.status < 300) {
          user = responseProf.data;
          const id = userAuth.id !== undefined ? userAuth.id : null;
          setSession(userAuth.accessToken, id, user);
          window.localStorage.setItem(HeaderEnum.USER_ID, userAuth.id!);
        } else {
          message = responseProf.statusText;
          code = responseProf.status;
        }
      } else {
        message = response.statusText;
        code = response.status;
      }
    } catch (err: any) {
      log('error: ' + err.message);
      message = err.message;
      code = err.statusCode;
    }

    const isAuthenticated = user !== null && user.id !== null;
    if (isAuthenticated) {
      dispatch({
        type: 'INITIALIZE',
        payload: {
          isAuthenticated,
          isInitialized: isAuthenticated,
          userAuth,
          user,
          message,
        },
      });
    }

    return { user: user, code: code, message: message };
  };

  const updateProfile = async () => {
    let user: UserInterface | null = null;
    const userAuth = state.userAuth!;
    try {
      const responseProf = await axios.get(CoreAPI.REACT_APP_API_USER_PROFILE_URL, {
        headers: {
          'x-user-id': userAuth.id,
          Authorization: `Bearer ${userAuth.accessToken}`,
        },
      });

      if (responseProf.status >= 200 && responseProf.status < 300) {
        user = responseProf.data;
      } else {
        log('error:', responseProf.status, responseProf.statusText);
      }
    } catch (err: any) {
      log('error:', err.message);
    }

    const isAuthenticated = user !== null && user.id !== null;
    if (isAuthenticated) {
      dispatch({
        type: 'UPDATE_PROFILE',
        payload: {
          isAuthenticated,
          isInitialized: isAuthenticated,
          userAuth,
          user,
          message: null,
        },
      });
    }
  };

  const logout = async () => {
    setSession(null, null, null);
    dispatch({ type: 'LOGOUT' });
    return true;
  };

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: 'jwt',
        login,
        logout,
        updateProfile,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export { AuthContext, AuthProvider };
