import React, { FC, useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { AuthContext } from '../../store/jwt/JwtContext';
import { getPermission } from '../../utils/permission.util';
import { AdminRoles, HeaderEnum } from 'gigabyte-shared';
import useHttp from '../../hooks/use-http';
import { CSpinner } from '@coreui/react-pro';
import { CoreAPI } from '../../constants/core-api';

const TechServiceSingleReport: FC = () => {
  const { id } = useParams();
  const { user } = useContext(AuthContext);

  const accessToken = window.localStorage.getItem('accessToken');
  const userId = window.localStorage.getItem(HeaderEnum.USER_ID);

  const { isLoading, error, sendGetRequest } = useHttp();
  const [htmlCode, setHtmlCode] = useState<string>('');

  if (!(getPermission(user, AdminRoles) || (accessToken && userId))) {
    window.close();
  }

  useEffect(() => {
    if (id) {
      sendGetRequest(CoreAPI.REACT_APP_API_TECH_SERVICE_ID_REPORT_URL, {
        callback: onGetTechServiceReportHandler,
        headers: { [HeaderEnum.RESOURCE_ID]: id },
      });
    }
  }, [id]);

  const onGetTechServiceReportHandler = (data: string) => {
    setHtmlCode(data);
  };

  return (
    <div>
      {isLoading && <CSpinner />}
      <div dangerouslySetInnerHTML={{ __html: htmlCode }} />
      {error && <div>{error}</div>}
    </div>
  );
};

export default TechServiceSingleReport;
